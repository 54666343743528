import React, { Component, Suspense } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import "antd/dist/reset.css";
import { store } from "./store";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Email App
const TheEmailApp = React.lazy(() => import("./views/apps/email/TheEmailApp"));

// Pages
const Login = React.lazy(() => import("./logicalContainers/LoginContainer"));

const App = () => {
  const auth = useSelector((state) => state.auth); // Access the auth state from the store
  const authentication = auth?.user?.token ? true : false;

  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Switch>
          <Route
            exact
            path="/login"
            name="Login Page"
            render={(props) => <Login {...props} />}
          />
          <Route
            path="/"
            name="Home"
            render={(props) =>
              authentication ? (
                <TheLayout {...props} />
              ) : (
                <Redirect from="/" to="/login" />
              )
            }
          />
        </Switch>
      </Suspense>
    </HashRouter>
  );
};

export default App;
