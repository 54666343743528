import { GET_LIVE_STREAMERS, LIVE_STREAMERS_LOADING_REQUEST } from "../constants/Constants";

let initialState = {
  liveStreams: [],
  liveStreamsLoading: false,
};

export const LiveStreamsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIVE_STREAMERS_LOADING_REQUEST:
      return { ...state, liveStreamsLoading: action.payload };
    case GET_LIVE_STREAMERS:
      return { ...state, liveStreams: action.payload };

    default:
      return state;
  }
};
