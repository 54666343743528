import {GET_COINS_USERS} from "../constants/Constants";

export const UsersCoinsReducer = (
  state = { usersCoins: [] },
  action
) => {
  switch (action.type) {
    case GET_COINS_USERS:
      return { ...state, usersCoins:action.payload };
    default:
      return state;
  }
};
