import { GET_MANAGEMENT_USERS, MANAGEMENT_USERS_REQUEST_LOADING } from "../constants/Constants";

let initialState = {
    managementUserLoading: false,
    managementUsers: [],
  };
  
  export const UserManagementReducer = (state = initialState, action) => {
    switch (action.type) {
      case MANAGEMENT_USERS_REQUEST_LOADING:
        return { ...state, managementUserLoading: action.payload };
  
      case GET_MANAGEMENT_USERS:
        return { ...state, managementUsers: action.payload };
  
      default:
        return state;
    }
  };