import {GET_USERS} from "../constants/Constants";

export const UsersReducer = (
  state = { users: [] },
  action
) => {
  switch (action.type) {
    case GET_USERS:
      return { ...state, users:action.payload };
    default:
      return state;
  }
};
