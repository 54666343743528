export const SET_USER_DATA = "SET_USER_DATA";
export const GET_USERS = "GET_USERS";
export const GET_ANALYTIC = 'GET_ANALYTIC';
export const GET_ANALYTIC_GRAPH = 'GET_ANALYTIC_GRAPH';

export const GET_COINS_USERS = 'GET_COINS_USERS';

export const GET_TOTAL_COINS = 'GET_TOTAL_COINS';

export const GET_CASE_USERS = 'GET_CASE_USERS';


export const USER_AD_LOADING_REQUEST = "USER_AD_LOADING_REQUEST"
export const APPROVED_AD_LOADING_REQUEST = "APPROVED_AD_LOADING_REQUEST"
export const PENDING_AD_LOADING_REQUEST = "PENDING_AD_LOADING_REQUEST"
export const REJECTED_AD_LOADING_REQUEST = "REJECTED_AD_LOADING_REQUEST"


export const GET_USER_ADS = 'GET_USER_ADS';
export const GET_APPROVED_ADS = 'GET_APPROVED_ADS';
export const GET_PENDING_ADS = 'GET_PENDING_ADS';
export const GET_REJECTED_ADS = 'GET_REJECTED_ADS';

export const GET_VIEWED_AD = 'GET_VIEWED_AD';

export const GET_MANAGEMENT_USERS = "GET_MANAGEMENT_USERS"
export const MANAGEMENT_USERS_REQUEST_LOADING = "MANAGEMENT_USERS_REQUEST_LOADING"


export const GET_LIVE_STREAMERS = 'GET_LIVE_STREAMERS';
export const LIVE_STREAMERS_LOADING_REQUEST = 'LIVE_STREAMERS_LOADING_REQUEST'







