import {GET_TOTAL_COINS} from "../constants/Constants";

export const totalCoinsReducer = (
  state = { totalCoins: {} },
  action
) => {
  switch (action.type) {
    case GET_TOTAL_COINS:
      return { ...state, totalCoins:action.payload };
    default:
      return state;
  }
};
