import {GET_ANALYTIC_GRAPH} from "../constants/Constants";

export const AnalyticGraphReducer = (state={}, action) => {
  switch (action.type) {
    case GET_ANALYTIC_GRAPH:
      return {  analyticsGraph: action.payload }
    default:
      return state;
  }
};
