import {
  GET_ACTIVE_ADS,
  USER_AD_LOADING_REQUEST,
  GET_PENDING_ADS,
  GET_USER_ADS,
  GET_VIEWED_AD,
  PENDING_AD_LOADING_REQUEST,
  APPROVED_AD_LOADING_REQUEST,
  REJECTED_AD_LOADING_REQUEST,
  GET_APPROVED_ADS,
  GET_REJECTED_ADS,
} from "../constants/Constants";

let initialState = {
  userAds: [],
  approvedAds: [],
  pendingAds: [],
  rejectedAds: [],
  viewedAd: null,
  userAdsLoading: false,
  approvedAdsLoading: false,
  pendingAdsLoading: false,
  rejectedAdsLoading: false,
};

export const AdManagerReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_AD_LOADING_REQUEST:
      return { ...state, userAdsLoading: action.payload };

    case APPROVED_AD_LOADING_REQUEST:
      return { ...state, approvedAdsLoading: action.payload };

    case PENDING_AD_LOADING_REQUEST:
      return { ...state, pendingAdsLoading: action.payload };

    case REJECTED_AD_LOADING_REQUEST:
      return { ...state, rejectedAdsLoading: action.payload };

    case GET_USER_ADS:
      return { ...state, userAds: action.payload };

    case GET_APPROVED_ADS:
      return { ...state, approvedAds: action.payload };

    case GET_PENDING_ADS:
      return { ...state, pendingAds: action.payload };

    case GET_REJECTED_ADS:
      return { ...state, rejectedAds: action.payload };

    case GET_VIEWED_AD:
      return { ...state, viewedAd: action.payload };
    default:
      return state;
  }
};
